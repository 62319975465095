/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Yıldız Sarayı’nın Birinci avludasunda bulunan Vâlide Sultan Kapısı’ndan girişte tam karşıda yer alan sıra odalardan oluşan yapı grubu Yâveran Dairesi’dir. Yâverlerin yanı sıra telgrafhane ve şifrehanenin bulunduğu “art nouveau” stilindeki yapı II. Abdülhamid’in baş mimarı Raimondo d’Aronco tarafından inşa edilmiştir. Bu çift kapılı yapı grubunun sol başında padişahın ve haremin güvenliğinden sorumlu tüfekçibaşının nöbet odası bulunmaktadır."), "\n", React.createElement(_components.p, null, "Yaveran Dairesi ve Nöbet Yeri; Saray’ın Resmi Bölümü’nün doğusunda, Harem duvarı önünde sürekli devam eden ince, uzun kütleli yapıdır. Ahşap,  bodrum kat, zemin kat ve birinci kat olmak üzere toplam üç kattan oluşan yapı , cephesinde sıralı pencereleri bulundurmaktadır. Yaveran Dairesi Dört farklı özgün girişi bulunmaktadır ve yapı içeride birbirine koridorlarla ve odalarla bağlanan bütüncül plan şemasına sahiptir. Saçak çıkmaları ile hareketlenen uzun kütlede saçak konsolları göze çarparken, Nöbet Yeri ile üslup farklılığı dikkat çekmektedir."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
